/**
 * Our Hero-component
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
// NPM
import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Carousel} from 'react-responsive-carousel';
import classNames from 'classnames';

// Peritus
import ResponsiveImage from '../../components/ResponsiveImage/ResponsiveImage';
import Block from 'components/Block';

// Styles
import * as styles from './hero.module.scss';
import {bgNot, cover} from 'components/Block/block.module.scss';
import './slider.scss';

// Assets
import RightArrow from '../../images/right-arrow.inline.svg';
import LeftArrow from './left-arrow.inline.svg';

const Hero = ({slides, autoSlide, ...props}) => {
    const HeroContainer = 1 < slides.length
        ? Carousel
        : React.Fragment;

    const carouselProps = 1 < slides.length
        ? {
            infiniteLoop: true,
            autoPlay: !!autoSlide,
            swipeable: true,
            showStatus: false,
            showThumbs: false,
            interval: 3500,
            renderArrowPrev: (clickHandler) => <button className={styles.arrowPrev} onClick={() => clickHandler()}>
                <LeftArrow/></button>,
            renderArrowNext: (clickHandler) => <button className={styles.arrowNext} onClick={() => clickHandler()}>
                <RightArrow/></button>,
        } : {};

    return (
        <Block className={classNames({[styles.hero]: true, [cover]: true, [bgNot]: true})} {...props}>
            <HeroContainer {...carouselProps}>
                {
                    slides.map((slide, index) => {
                        const Title = !index ? `h1` : 'div';
                        const titleHtml = slide.title
                            ? `${slide.title}`
                            : '';

                        return (
                            <div key={index} className={classNames({[styles.slide]: true, [cover]: true})}>
                                <ResponsiveImage className={classNames({[styles.image]: true, [cover]: true})}
                                    image={slide.image}
                                    style={{position: 'absolute'}}
                                />
                                {slide.type === 'video' && !!slide.video &&
                                <video className={styles.video} playsInline muted={true} autoPlay loop>
                                    <source
                                        src={slide.video.gatsbyFileUrl
                                            ? slide.video.gatsbyFileUrl
                                            : slide.video.mediaItemUrl}
                                        type={slide.video.mimeType}
                                    />
                                </video>
                                }
                                <div className={styles.overlay}>
                                    {
                                        titleHtml &&
                                        <Title className={styles.title}
                                            dangerouslySetInnerHTML={{__html: titleHtml}}
                                        />
                                    }
                                    {
                                        slide.subline && slide.subline.map((subline, sublineIndex) => (
                                            <p className={styles.subline} key={sublineIndex}>{subline.text}</p>
                                        ))
                                    }
                                </div>
                            </div>
                        );
                    })
                }
            </HeroContainer>
        </Block>
    );
};

export default Hero;
