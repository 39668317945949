// extracted by mini-css-extract-plugin
export var cover = "hero-module--cover--zsOcR";
export var hero = "hero-module--hero--CLe0x hero-module--cover--zsOcR";
export var slide = "hero-module--slide--qpOJP hero-module--cover--zsOcR";
export var image = "hero-module--image--1IsVP hero-module--cover--zsOcR";
export var video = "hero-module--video--NIPms";
export var overlay = "hero-module--overlay--lgOE+";
export var title = "hero-module--title--vOwLd";
export var subline = "hero-module--subline--Gadt7";
export var arrowNext = "hero-module--arrow-next--QjCLE";
export var arrowPrev = "hero-module--arrow-prev--hgfgC";